<template>

	<!-- <div>

		<div class="monitors-winners">

			<div class="body winners-not-typed">

				<div class="container-fluid header-info">

					<div class="row">

						<div class="col-2 divLogo">
							<img src="/images/logotipo.png"/>
						</div>

						<div class="col header">
							<span style="font-size: 25px;font-family: monospace;text-transform: uppercase;">Analise Nao conformidades</span>
							<span style="font-size: 17px;"><b>Cotacao</b>: #{{ $route.params.id }}</span>
							<span style="font-size: 17px;"><b>Empresa</b>: {{ request.client ? request.client.group.toUpperCase() : '-' }}</span>
						</div>

					</div>

					<div class="row action-buttons">

						<div class="col-6 filters">
							<div>
								<input type="radio" name="filter" @change="filter('ALL')" checked="checked"><span class="tag">Todos</span>
								<input type="radio" name="filter" @change="filter('NOT_BOUGHT')"><span class="tag">Apenas Não Comprados</span>
								<input type="radio" name="filter" @change="filter('NOT_TYPED')"><span class="tag">Apenas Não Digitados</span>
							</div>
						</div>

						<div class="col">
							<button class="order" v-on:click="order()">
								<i class="fa fa-shopping-cart"></i>
								<span>Gerar Cotação</span>
							</button>
							<button v-on:click="print()">
								<span style="color:rgb(113, 114, 125);padding-right: 12px;font-size: 18px;" class="icon-icon_print"></span>
								Imprimir
							</button>
							<router-link :to="{ name: 'cliente-monitores-vencedores', params: { id: $route.params.id} }" tag="button" v-bind:class="'back'">
								<i class="fa fa-arrow-left" style="margin-right: 8px;font-size: 1.5rem;color: #71727d;padding-bottom: 2px;"></i>
								Voltar
							</router-link>
						</div>

					</div>

					<div class="row">
						<div class="col">
							<div class="loader" :class="{ hidden : !is_loading }">
								<cc-loader />
							</div>

							<table v-for="store in stores" :class="{ hidden : is_loading }" :key="store.loj_id + Math.random()">

								<thead>

									<tr class="store-menu">
										<th>Produto</th>
										<th class="orders">Código</th>
										<th class="center">Embalagem</th>
										<th class="center">Quantidade</th>
										<th class="center">Status</th>
                                        <th class="center"></th>
									</tr>

									<tr class="store-name">
										<th colspan="6">
											{{ store.loj_nomeFantasia.toUpperCase() }}
										</th>
									</tr>

								</thead>

								<tbody>

									<tr v-for="(product) in visible_products(store)" v-bind:key="product.pro_ean + Math.random()">
										<td class="product-description">
											<span class="name">{{ product.pro_descricao }}</span>
										</td>
										<td>
											{{ product.pro_ean }}
										</td>
										<td class="orders">
											{{ product.pro_generico == 1 ? product.pro_unidade_compra : product.pro_embalagem }}
										</td>
										<td class="orders">
											{{ product.quantidade }}
										</td>
										<td class="orders">
											{{ product.is_discontinued ? 'Produto Cancelado' : product.pro_status }}
										</td>
                                        <td class="orders">
											<button v-if="!product.is_discontinued" title="cancelar" class="descontinue" @click="() => discontinue(product, store, 1)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                            <button v-else title="reposicionar produto" class="reposition" @click="() => discontinue(product, store, 0)">
                                                <i class="fas fa-redo-alt"></i>
                                            </button>
										</td>
									</tr>

								</tbody>

							</table>
						</div>

					</div>

				</div>

			</div>

		</div>

	</div> -->
	<div class="page-containear">
		<div class="page-route-select">
				<router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
					Home
				</router-link>
				<span class="material-icons page-route-select-icon">play_arrow</span>
				<router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
					Monitor de Cotação
				</router-link>
				<span class="material-icons page-route-select-icon">play_arrow</span>
				<router-link :to="'/cliente/monitores/vencedores/'+ $route.params.id" class="page-route-select-text">
					Comparativo de Preço
				</router-link>
				<span class="material-icons page-route-select-icon">play_arrow</span>
				<div class="page-route-select-text">
					Não Digitados
				</div>
		</div>
		<div class="page-title">Não Digitados</div>
		<div class="page-buttons-container">
			<!-- <div class="page-buttons-text">Analise não conformidades</div> -->
			<div class="page-filter-option-container">
				<div class="page-filter-option">
					<span v-if="filterSelected === 'ALL'" class="material-icons-outlined page-filter-icon">check_box</span>
					<span v-else class="material-icons-outlined page-filter-icon" v-on:click="filter('ALL')">check_box_outline_blank</span>
					<div>Todos</div>
				</div>
				<div class="page-filter-option">
					<span v-if="filterSelected === 'NOT_BOUGHT'" class="material-icons-outlined page-filter-icon">check_box</span>
					<span v-else class="material-icons-outlined page-filter-icon" v-on:click="filter('NOT_BOUGHT')">check_box_outline_blank</span>
					<div>Apenas Não Comprados</div>
				</div>
				<div class="page-filter-option">
					<span v-if="filterSelected === 'NOT_TYPED'" class="material-icons-outlined page-filter-icon">check_box</span>
					<span v-else class="material-icons-outlined page-filter-icon" v-on:click="filter('NOT_TYPED')">check_box_outline_blank</span>
					<div>Apenas Não Digitados</div>
				</div>
			</div>
			<div class="page-buttons">
				<StandardButton class="page-buttons-button" :action="order" :iconName="'shopping_cart'" :text="'Gerar Cotação'"/>
				<StandardButton class="page-buttons-button" :action="print" :iconName="'print'" :text="'Imprimir'"/>
				<StandardButton class="page-buttons-button-back" :action="goBack" :iconName="'arrow_back'" :text="'Voltar'"/>
			</div>
		</div>
		<!-- <div class="page-quotation-info">
			<div class="page-quotation-info-row">
				<div>Cotação:</div>
				<div>#{{ $route.params.id }}</div>
			</div>
			<div class="page-quotation-info-row">
				<div>Empresa:</div>
				<div>{{ group_name().toUpperCase() }}</div>
			</div>
		</div> -->
		<!-- <div class="page-content-loader" v-if="is_loading">
			<cc-loader />
		</div> -->
		<cc-loader-full v-if="is_loading"/>
		<div v-else class="page-content-overflow">
			<div class="page-content-container">
				<div class="page-content-store" v-for="(store, index) in stores" :key="index">
					<div v-if="visible_products(store).length > 0">
						<div class="page-content-title">{{store.loj_nomeFantasia}}</div>
						<div class="page-content-header">
							<div class="product-width">Produto</div>
							<div class="code-width">Código</div>
							<div class="packing-width">Embalagem</div>
							<div class="quantity-width">Quantidade</div>
							<div class="status-width">Status</div>
							<div class="action-width">Ação</div>
						</div>
						<div v-for="(product, index) in visible_products(store)" :key="index">
							<div class="page-content-product" :class="getSpecialBackground(index)">
								<div class="product-width page-content-product-mobile">
									<div class="page-content-product-mobile-header">Produto</div>
									{{ product.pro_descricao }}
								</div>
								<div class="code-width page-content-product-mobile">
									<div class="page-content-product-mobile-header">Código</div>
									{{ product.pro_ean }}
								</div>
								<div class="packing-width page-content-product-mobile">
									<div class="page-content-product-mobile-header">Embalagem</div>
									{{ product.pro_generico == 1 ? product.pro_unidade_compra : product.pro_embalagem }}
								</div>
								<div class="quantity-width page-content-product-mobile">
									<div class="page-content-product-mobile-header">Quantidade</div>
									{{ product.quantidade }}
								</div>
								<div class="status-width page-content-product-mobile">
									<div class="page-content-product-mobile-header">Status</div>
									{{ product.is_discontinued ? 'Produto Cancelado' : product.pro_status }}
								</div>
								<div class="action-width page-content-product-mobile">
									<div class="page-content-product-mobile-header">Ação</div>
									<span v-if="product.is_discontinued" class="material-icons page-content-product-icon" @click="() => discontinue(product, store, 0)">refresh</span>
									<span v-else class="material-icons page-content-product-icon" @click="() => discontinue(product, store, 1)">delete_outline</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Pagination from "@/components/cliente/base-components/Pagination";
import * as CONSTANTS from "@/constants/constants";
import QuotationService from "@/services/QuotationService";
import RequestService from '@/services/v3/request.service'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import StandardButton from '@/components/ui/buttons/StandardButton.vue'
import { mapState } from 'vuex'

export default {
  components: {
    ccPagination: Pagination,
	StandardButton
  },
  data() {
    return {
		is_loading: true,
		CONSTANTS: CONSTANTS,
		stores: [],
		products : [],
		service: new QuotationService(),
        svc: new RequestService(),
		quotationId : [],
        request: {},
        filterSelected: "ALL",
		breadcrumbs : [
				{
					link: CONSTANTS.ROUTE_MONITORS,
					name: 'Monitor de Cotações'
				},
				{
					name: 'Não digitados'
				}
			]
    	};
  	},
	methods: {
		getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
		goBack () { this.$router.push('/cliente/monitores/vencedores/' + this.$route.params.id)	},
		filter(option) {
            this.filterSelected = option;
			this.stores.forEach(store => {

				store.produtos.forEach(p => {
					if(option == "ALL") {
						p.visible = true;
					} else if(option == "NOT_BOUGHT" && p.pro_status == "Não Comprado") {
						p.visible = true;
					} else if(option == "NOT_TYPED" && p.pro_status == "Não Digitado") {
						p.visible = true;
					} else {
						p.visible = false;
					}
				})
			})
			this.$forceUpdate();
		},
        discontinue(product, store, discontinue) {
            let params = {}
            if(discontinue) {
                params = {
                    title: "Remover pedido da loja?",
                    text: "Esse produto não será mais requisitado nesta loja.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, desejo remover!"
                }
            } else {
                params = {
                    title: "Reposicionar produto da loja?",
                    text: "Esse produto será reposicionado nesta loja.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, desejo reposicionar!"
                }
            }
            this.$swal.fire(params)
			.then(result => {
                if (result.value) {
                    this.is_loading = true;
					this.service
				    .discontinue_product(store.cotacao_id, product.pro_id, discontinue)
				    .then(response => response.data)
				    .then(data => {
                      this.load_stores()
				    })
				    .catch(error => {
				      this.$swal.fire(
				        "Erro!",
						"Ocorreu um erro ao descontinuar produto.",
				        "error"
					  );
					  this.is_loading = false;
				      ErrorHandlerService.handle(error, this.$store);
				    });
				}
			});
        },
		order(){

			this.$swal.fire({
				title: "Tem certeza que deseja gerar as solicitações?",
				text: "Sua solicitação será gerada para a loja.",
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Sim, desejo gerar a solicitação!"
			})
			.then(result => {
                if (result.value) {
                    this.is_loading = true;
					this.service
				    .orderNotTyped(this.quotationId, this.filterSelected)
				    .then(response => response.data)
				    .then(data => {

				      this.$swal.fire(
				        "Solicitação gerada!",
				        "Solicitação gerada com sucesso",
				        "success"
					  );
					  this.is_loading = false;
				    })
				    .catch(error => {
				      this.$swal.fire(
				        "Erro!",
						"Ocorreu um erro ao gerar solicitação.",
				        "error"
					  );
					  this.is_loading = false;
				      ErrorHandlerService.handle(error, this.$store);
				    });
				}
			});
		},
		print() {
			self.print();
		},
		visible_products(store) {
			return store.produtos.filter(s => s.visible == true);
		},
		group_name() {
			return localStorage.getItem("grupo")
		},
		formatPrice(value) {
			let val = (value/1).toFixed(2).replace('.', ',')
			return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
		},
		load_stores() {
			this.quotationId = this.$route.params.id;
			this.service.load_not_typed_per_store(this.$route.params.id)
			.then(response => response.data)
			.then(data => {

				this.stores = data.lojas;

				this.stores.forEach(store => store.produtos.forEach(s => s.visible = true))

				this.is_loading =  false;

			})
			.catch(error => {
				this.is_loading =  false;
				ErrorHandlerService.handle( error, this.$store );
			});
		},
        load() {

            this.loading = true;

            this.svc.list({ request_id: this.quotationId}).then(response => response.data).then(data => {
                this.request = data[0]
                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
                ErrorHandlerService.handle(error, this.$store, this.$store);
            });
        }
	},
	created(){
		this.load_stores();
        this.load()
	}
}
</script>

<style lang="scss" scoped>
	@import './without-offer.page';
	.page-containear{
		font-size: 1vw;
		padding-right: 4vw;
	}
	.page-content-loader{
		margin-top: 5vh;
	}
	.page-route-select{
		display: flex;
		align-items: center;
	}
	.page-route-select-text{
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon{
  color:  var(--primary-color);
  margin-right: 20px;
}
.page-title{
  font-weight: 700;
  font-size: 2.5em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 40px;
}
.page-mobile-info{
	width: 36%;
	margin-top: 3vh;
}
.page-buttons-container{
	display: flex;
	margin-top: 3vh;
	align-items: center;
	justify-content: space-between;
}
.page-filter-option-container{
	display: flex;
	width: 45%;
	justify-content: space-between;
}
.page-buttons{
	display: flex;
	width: 40%;
	justify-content: space-between;
}
.page-buttons-text{
	font-weight: 700;
	font-size: 1.6em;
	letter-spacing: 0.15px;
	color: #606060;
	flex: 1;
}
.page-quotation-info{	width: 50%; }
.page-quotation-info-row{
	display: flex;
	border-bottom: 1px solid #E5E5E5;
	padding-bottom: 1vh;
	width: 100%;
	justify-content: space-between;
	font-weight: 400;
	font-size: 1.3em;
	letter-spacing: 0.15px;
	color: #707070;
	margin-bottom: 2vh;
}
.page-filter-option{
	display: flex;
	align-items: center;
	font-weight: 300;
	font-size: 1.45em;
	color: #505050;
}
.page-filter-icon{
	margin-right: 1vw;
	color: var(--primary-color);
	font-size: 1.4em;
	cursor: pointer;
}
.page-content-container{
	margin-top: 5vh;
}
.page-content-title{
	background-color: #ff660026;
  border-radius: 7px;
	font-weight: 400;
	font-size: 1.25em;
	padding: 1vh 1vw;
	color: #202020;
}
.page-content-store{ margin-bottom: 4.5vh; }
.page-content-header{
	display: flex;
	background: #FFFEFC;
	border: 0.5px solid #E5E5E5;
	padding: 1vh 0;
	padding-left: 1vw;
	margin-top: 2vh;
	font-weight: 400;
	font-size: 1.15em;
	color: #605F5F;
	margin-bottom: 2vh;
}
.product-width{
	flex: 1;
}
.code-width{	width: 12%; }
.packing-width{	width: 12%; }
.quantity-width{	width: 12%;	}
.status-width{	width: 20%;	}
.action-width{	width: 12%;	}
.page-content-product{
	display: flex;
	padding: 1vh 0;
	padding: 1vw;
	font-weight: 300;
	font-size: 1.15em;
	color: #605F5F;
	align-items: center;
}
.page-content-product-icon{
	color: var(--primary-color);
	font-size: 2em;
	cursor: pointer;
}
.page-content-overflow{ overflow-x: auto; }
.page-table-line-special{  background: #F7F7F7; }
.page-content-product-mobile-header{display: none;}
 @media (max-width: 1100px) {
	.page-containear{ font-size: 1.1vw; }
	.page-filter-option-container{ width: 55%;}
}
 @media (max-width: 1000px) { .page-containear{ font-size: 1.2vw; }}
 @media (max-width: 870px) { 
	.page-containear{	font-size: 1.3vw;	}
	.page-content-container{ font-size: 11px; }
	.page-mobile-info{ width: 70%;}
	.page-buttons-container{
		flex-direction: column-reverse;
		align-items: flex-start;
	}
	.page-buttons{
		width: 50%;
		margin-bottom: 2vh;
	}
	.page-filter-option-container{
		width: 65%;
	}
}

 @media (max-width: 700px) {
	.page-containear{ font-size: 1.5vw; }
	.page-quotation-info{
		width: 100%;
		margin-top: 2vh;
	}
	.page-buttons{ width: 60%; }
	.page-filter-option-container{ width: 75%; }
	.page-content-header{display: none;}
	.page-content-product{
		flex-direction: column;
		gap: 1vh;
		margin-bottom: 3vh;
	}
	.page-content-product-mobile{
		display: flex; 
		justify-content: space-between;
		width: 100%;
		font-size: 1.5em;
		text-align: end;
	}
	.page-content-product-mobile-header{
		display: flex;
		font-weight: bold;
		font-size: 1.3em;
	}

}

 @media (max-width: 550px) { 
	.page-containear{
		font-size: 1.8vw;
		padding: 0 15px 15px 0;
	}
	.page-filter-option{ margin-right: 0;}
}
 @media (max-width: 500px) { 
	.page-containear{ font-size: 2.2vw; }
	.page-buttons{width: 100%;}
	.page-buttons-button{width: 30%;}
	.page-buttons-button-back{width: 30%;}
	.page-buttons-text{ margin-bottom: 2vh;}
	.page-filter-option-container{ width: 100%;}
}
 @media (max-width: 450px) { .page-containear{ font-size: 2.5vw; }}
 @media (max-width: 400px) {
	.page-containear{ font-size: 3.1vw; }
	.page-filter-option{ padding: 0.5vh 0; font-size: 1.6em;}
	.page-filter-icon{ font-size: 2em;}
	.page-mobile-info{width: 100%;}
	.page-buttons{ flex-direction: column; }
	.page-buttons-button{width: 100%; margin-bottom: 2vh;}
	.page-buttons-button-back{width: 100%; margin-bottom: 2vh;}
	.page-filter-option-container{ flex-direction: column;}
	.page-content-container{font-size: 13px;}
}
@media print {
	.page-content-container{min-width: 0;}
}
</style>
